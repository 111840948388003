import _ from 'lodash';
import moment from 'moment';
import styled, { keyframes } from 'styled-components';
import { ReactNode } from 'react';
import { getStore, setStore, useStore } from 'store';
import { useStated } from 'hooks/Hooks';
import { SqlDate, ToCurrency } from 'utils/Dates';
import { ApiStore, BackendURL, Entry, Put } from 'utils/Api';
import { Redirect } from 'react-router-dom';
import { getDuration } from 'App';
import { BottomBox } from 'components/ConfirmBox';
import { SelectBox } from 'components/SelectBoxes';
import { TabParent } from './Blocks/TabParent';
import { AddressResult, SearchList } from './Blocks/SearchList';
import { usePrice } from 'components/UsePrice';
import { Loader } from 'components/Loader';
import { Picture } from 'components/Picture';

const fadeIn = keyframes`
	0% { opacity: 0; }
	100% { opacity: 1; }
`;

const ModalContainer = styled.div`
	position: fixed;
	top: 0px;
	width: 100%;
	height: 100%;
	left: 0px;
	background: #00000044;
	z-index: 100000;
	padding: 10px;
	display: flex;
	justify-content: center;
	align-items: center;

	opacity: 0;
	animation: forwards ${ fadeIn };
	animation-duration: 300ms;
	animation-iteration-count: 1;
	animation-timing-function: ease-in-out;
`;

const ModalInner = styled.div`
	background: #fff;
	padding: 20px 12px;
	width: 100%;
	max-width: 500px;
	border: 1px solid #00000033;
	border-radius: 8px;
`;

const Details = styled.table`
	tr > td:first-child {
		min-width: 125px;
		color: #000a;
	}
`;

const Table = styled.table`
	.table-vertical-top td {
		vertical-align: top;
	}
	.td-nowrap {
		white-space: nowrap !important;
	}
`;

function Modal(props: { children?: ReactNode; onClose?: () => void; })
{
	const { children, onClose } = props;
	return (
		<ModalContainer onClick={() => onClose?.()}>
			<ModalInner onClick={e => e.stopPropagation()}>{children}</ModalInner>
		</ModalContainer>
	);
}

export function TabConfirm()
{
	const [state, set] = useStated({
		loading: false,
		redirect: '',
		distance: undefined as void | number,
		deliveryModal: undefined as { onConfirm: () => Promise<void>; } | undefined,
	});

	usePrice();
	const [order, appStatus] = useStore(s => [s.order, s.appStatus]);
	const { start, end, order_lines, company, office, resource, resources, customer, calcprice } = order;
	const { distance, deliveryModal, loading, redirect } = state;
	const { formValid, agreed } = appStatus || {};

	const getDeliveryAddress = (): string =>
	{
		const { customer } = getStore()?.order || {};
		const { delivery_different } = customer;
		const { address, postal, city } = customer;
		const { delivery_address, delivery_postal, delivery_city } = customer;
		return [
			delivery_different ? delivery_address : address,
			delivery_different ? delivery_postal : postal,
			delivery_different ? delivery_city : city,
		]
			.map(v => String(v || '').trim())
			.join(' ')
			.trim();
	};

	const getDistance = async () =>
	{
		const deliveryAddress = getDeliveryAddress();
		if (!deliveryAddress) return;

		console.log('deliveryAddress:', deliveryAddress);
		let coords: any = await fetch(`${ BackendURL }/map_route?search=${ deliveryAddress }`);
		coords = await coords.json();
		coords = coords?.data?.[0];
		console.log('coords:', coords, 'resource?.coords:', resource?.coords);

		let distance: any = await fetch(
			`${ BackendURL }/map_distance?from=${ coords?.lat },${ coords?.lon }&to=${ resource?.coords?.lat },${ resource?.coords?.lon }`,
		);
		distance = await distance.json();
		console.log('distance:', distance);
		distance = Math.ceil(distance?.data?.[0]?.distance / 1000);

		return distance;
	};

	// Redirects
	const validCustomer = formValid && agreed && customer?.name && customer?.phone && customer?.email;
	if (!validCustomer) return <Redirect to={'/yhteystiedot'} />;

	const validSchedule = office && resource && start && end;
	if (!validSchedule) return <Redirect to={'/vahvistus'} />;

	if (redirect) return <Redirect to={redirect} />;

	const subresources = _.filter<Entry>(resources?.data, (f: Entry) =>
		_.find(f?.parents, { id: String(resource?.id || resource || '') }),
	);

	return (
		<>
			<TabParent>
				<div className='row p-0 m-0 mt-2 mb-3'>
					{subresources.length > 0 && (
						<>
							<div className='col-12 col-md-6 col-lg-5'>
								<div className='p-2'>
									<p className='mt-2 mb-3'>
										<b style={{ fontSize: '1.25em' }}>Lisävalinnat</b>
									</p>
									<div style={{ display: 'flex', maxWidth: 'calc(100vw - 16px)', flexWrap: 'wrap' }}>
										{_.map(subresources, (subres, k) =>
										{
											const isDelivery = subres?.price_by_distance;
											const selected = _.find(order_lines, { resource: subres?.id });

											return (
												<SelectBox
													key={k}
													style={{ flex: 1, minWidth: '50%' }}
													onClick={async () =>
													{
														if (!isDelivery && subres?.max_amount > 1) return;

														if (selected)
														{
															_.remove(order_lines, { resource: subres?.id });
															return setStore({ order });
														}

														if (isDelivery)
														{
															return set({
																deliveryModal: {
																	onConfirm: async () =>
																	{
																		let distance = await getDistance();

																		if (_.isFinite(distance) && distance < subres?.min_amount)
																		{
																			distance = subres?.min_amount;
																		}

																		_.remove(order_lines, { resource: subres?.id });
																		if (_.isFinite(distance) && distance <= subres?.max_amount)
																		{
																			order_lines.push({
																				resource: subres?.id,
																				amount: distance,
																			});
																		}

																		set({ distance, deliveryModal: undefined });
																		setStore({ order });
																	},
																},
															});
														}

														order_lines.push({
															resource: subres?.id,
															amount: isDelivery && distance !== undefined ? distance : 1,
														});
														setStore({ order });
													}}
												>
													<div>
														<Picture
															src={subres?.picture}
															alt={subres?.name}
														/>
														<div>
															<div>
																{subres?.name}
																{Boolean(subres?.description?.length) && (
																	<div>
																		<small>
																			<i className='fa fa-info-circle mr-1 mt-2' />
																			{subres?.description}
																		</small>
																	</div>
																)}
																{isDelivery && (
																	<>
																		{' '}
																		<small>
																			({subres?.min_amount} - {subres?.max_amount} km)
																		</small>{' '}
																	</>
																)}
															</div>
															{
																// checked &&
																isDelivery && (
																	<div>
																		<small>{getDeliveryAddress()}</small>
																	</div>
																)
															}
															<div>
																{!isDelivery ? (
																	selected && selected?.amount > 1 ? (
																		<>
																			<small>
																				{Number(selected?.amount || 0)}
																				<> x </>
																				{ToCurrency(subres?.base_price || 0)}
																				<> = </>
																			</small>
																			{ToCurrency(selected?.amount * subres?.base_price || 0)}
																		</>
																	) : (
																		<>{ToCurrency(subres?.base_price || 0)}</>
																	)
																) : (
																	(Boolean(distance !== undefined && !_.isFinite(distance)) && (
																		<small className='text-danger'>
																			Kotiinkuljetus ei mahdollinen
																		</small>
																	)) ||
																	(distance !== undefined && distance > subres?.max_amount && (
																		<small className='text-danger'>
																			Osoite on liian kaukana ({distance} km)
																			{/* (max. { v?.max_amount} km) */}
																		</small>
																	)) ||
																	(distance === undefined && (
																		<>{ToCurrency(subres?.base_price)} / km</>
																	)) ||
																	ToCurrency(
																		subres?.base_price *
																		(isDelivery && distance !== undefined ? distance : 1),
																	)
																)}
															</div>

															{subres?.max_amount <= 1 || isDelivery ? (
																<label
																	className='switch m-0 mt-1'
																	style={{ alignSelf: 'center' }}
																>
																	<input
																		type='checkbox'
																		checked={Boolean(selected)}
																		autoFocus={false}
																	/>
																	<span className='switchslider round' />
																</label>
															) : (
																<div
																	className='mt-1'
																	style={{
																		display: 'flex',
																		alignSelf: 'center',
																		alignItems: 'center',
																	}}
																>
																	<button
																		type='button'
																		className='btn btn-success'
																		style={{ pointerEvents: 'auto' }}
																		disabled={!selected?.amount}
																		onClick={async e =>
																		{
																			e.preventDefault();
																			e.stopPropagation();
																			if (!selected) return;
																			if (selected.amount > 0) selected.amount--;
																			if (selected.amount === 0)
																			{
																				_.remove(order_lines, { resource: subres?.id });
																			}

																			setStore({ order });
																		}}
																	>
																		<i className='fa fa-minus'></i>
																	</button>
																	<div style={{ padding: '0 16px' }}>
																		{Number(selected?.amount) || 0}
																	</div>
																	<button
																		type='button'
																		className='btn btn-success'
																		style={{ pointerEvents: 'auto' }}
																		disabled={Number(selected?.amount) >= subres?.max_amount}
																		onClick={async e =>
																		{
																			e.preventDefault();
																			e.stopPropagation();
																			if (!selected && subres)
																			{
																				order_lines.push({
																					resource: subres?.id,
																					amount: 1,
																				});
																			}
																			if (selected && selected.amount < subres?.max_amount)
																			{
																				selected.amount++;
																			}
																			return setStore({ order });
																		}}
																	>
																		<i className='fa fa-plus'></i>
																	</button>
																</div>
															)}
														</div>
													</div>
												</SelectBox>
											);
										})}
									</div>
								</div>
							</div>
						</>
					)}

					<div className='col-12 col-md-6 col-lg-4'>
						<div className='p-2'>
							<p className='mt-2 mb-3'>
								<b style={{ fontSize: '1.25em' }}>Varauksen tiedot</b>

								{Boolean(resource?.allow_customer_message) && (
									<div style={{ display: 'flex', gap: '1em', marginTop: '1em' }}>
										<textarea
											className='form-control w-100'
											style={{ fontSize: '1em' }}
											placeholder={`Vapaamuotoinen viesti`}
											value={order.infotext ?? ''}
											onChange={e =>
											{
												const { value } = e.target;
												setStore({ order: { ...order, infotext: value } });
											}}
										/>
									</div>
								)}
							</p>

							<Details className='mb-4'>
								<tbody>
									<tr>
										<td className='pr-3'>Nimi</td>
										<td>{customer.name}</td>
									</tr>
									<tr>
										<td className='pr-3'>Osoite</td>
										<td>{customer.address}</td>
									</tr>
									<tr>
										<td className='pr-3'>Postinumero</td>
										<td>{customer.postal}</td>
									</tr>
									<tr>
										<td className='pr-3'>Toimipaikka</td>
										<td>{customer.city}</td>
									</tr>
									<tr>
										<td className='pr-3'>Puhelin</td>
										<td>{customer.phone}</td>
									</tr>
									<tr>
										<td className='pr-3'>Sähköposti</td>
										<td>{customer.email}</td>
									</tr>
									{!!customer.iscompany && (
										<tr>
											<td className='pr-3'>Y-tunnus</td>
											<td>{customer.vatnumber}</td>
										</tr>
									)}
								</tbody>
							</Details>
							<div className='mb-1'>
								<b>{resource.name}</b>
							</div>
							<Details className='mb-4'>
								<tbody>
									<tr>
										<td className='pr-3'>Alkaa</td>
										<td>{moment(start).format('HH:mm D.M.Y')}</td>
									</tr>
									<tr>
										<td className='pr-3'>Päättyy</td>
										<td>{moment(end).format('HH:mm D.M.Y')}</td>
									</tr>
									<tr>
										<td className='pr-3'>Varauksen kesto</td>
										<td>{getDuration(new Date(start), new Date(end))}</td>
									</tr>
									{!!calcprice && (
										<tr>
											<td className='pr-3'>Varausajan hinta</td>
											<td>{ToCurrency(calcprice?.price)}</td>
										</tr>
									)}
								</tbody>
							</Details>
							{Boolean(calcprice?.order_lines?.length) && (
								<>
									<div className='mb-1'>
										<b>Lisävalinnat</b>
									</div>
									<Table className='mb-4 w-100 table-vertical-top'>
										<tbody>
											{_.map(calcprice?.order_lines, (line, k) => (
												<tr key={k}>
													<td className='pr-3'>
														{line?.resource?.name ||
															_.find(resources?.data, (f: any) => f.id === line?.resource)?.name ||
															''}
													</td>
													{line?.amount > 1 && (
														<>
															<td
																className='pr-2 td-nowrap'
																style={{ color: '#000a' }}
															>
																{line?.amount}
																<> x </>
																{ToCurrency(line?.resource?.base_price || line?.base_price)}
															</td>
															<td className='pr-2'>=</td>
														</>
													)}
													<td className='pr-2 td-nowrap'>
														{ToCurrency(line?.amount * (line?.resource?.base_price || line?.base_price))}
													</td>
												</tr>
											))}
										</tbody>
									</Table>
								</>
							)}

							{!!calcprice && (
								<p style={{ borderTop: '1px solid #ccc', padding: '8px 0 16px 0', fontSize: '0.9rem' }}>
									<b>Yhteensä: </b>
									<span style={{ color: '#555' }}>{ToCurrency(calcprice?.total)}</span>
								</p>
							)}
						</div>
					</div>

					{resource?.picture && (
						<div
							className='col col-lg-3'
							style={{
								display: 'flex',
								alignItems: 'start',
								justifyContent: 'center',
								height: '300px',
								overflow: 'hidden',
							}}
						>
							<Picture src={resource.picture} />
						</div>
					)}
				</div>
			</TabParent>

			<BottomBox active={true}>
				<div style={{ display: 'flex', minWidth: '250px' }}>
					{Boolean(company?.pay_cashier && office?.pay_cashier) && (
						<button
							type='submit'
							disabled={!agreed || !calcprice || loading}
							className={'btn btn-success m-1'}
							style={{ position: 'relative', overflow: 'hidden', flex: 'auto' }}
							onClick={async e =>
							{
								e.preventDefault();
								set({ loading: true });
								try
								{
									const res = await Put({
										path: 'schedule',
										body: {
											customer,
											start: SqlDate(start),
											ending: SqlDate(end),
											resource: resource?.id,
											office: office?.id,
											order_lines,
											infotext: order?.infotext,
											payment_type: 'cashier',
										},
									});

									if (!res?.id) throw Error(`Varaus ei tallentunut`);
									window.location.replace(
										window.location.origin + '/valmis/' + res.id + '?apikey=' + ApiStore.apikey,
									);
								}
								catch (e)
								{
									alert(`Tapahtui virhe: ${ String(e) }`);
									set({ loading: false });
								}
							}}
						>
							{company?.name?.match(/infoni/i) ? 'Maksa myöhemmin' : 'Maksa paikan päällä'}

							{Boolean(!calcprice || loading) && <Loader />}
						</button>
					)}

					{Boolean(company?.pay_invoice && office?.pay_invoice) && (
						<button
							type='submit'
							disabled={!agreed || !calcprice || loading}
							className={'btn btn-success m-1'}
							style={{ position: 'relative', overflow: 'hidden', flex: 'auto' }}
							onClick={async e =>
							{
								e.preventDefault();
								set({ loading: true });
								try
								{
									const res = await Put({
										path: 'schedule',
										body: {
											customer,
											start: SqlDate(start),
											ending: SqlDate(end),
											resource: resource?.id,
											office: office?.id,
											order_lines,
											infotext: order?.infotext,
											payment_type: 'invoice',
										},
									});

									if (!res?.id) throw Error(`Varaus ei tallentunut`);
									window.location.replace(
										window.location.origin + '/valmis/' + res.id + '?apikey=' + ApiStore.apikey,
									);
								}
								catch (e)
								{
									alert(`Tapahtui virhe: ${ String(e) }`);
									set({ loading: false });
								}
							}}
						>
							{'Maksa laskulla'}

							{Boolean(!calcprice || loading) && <Loader />}
						</button>
					)}

					{Boolean(office?.pay_internet || company?.pay_internet) && (
						<button
							type='button'
							disabled={!agreed || !calcprice || loading}
							className={'btn btn-success m-1'}
							style={{ position: 'relative', overflow: 'hidden', flex: 'auto' }}
							onClick={() => set({ redirect: '/maksu' })}
						>
							Maksa nyt
							{Boolean(!calcprice || loading) && <Loader />}
						</button>
					)}
				</div>

				<small className='mb-0'>Varauksen tiedot lähetetään antamaasi sähköpostiosoitteeseen</small>
			</BottomBox>
			{deliveryModal && (
				<Modal onClose={() => set({ deliveryModal: undefined })}>
					<h6>Valitse toimitusosoite</h6>
					<hr />
					<div>
						<div>
							<span className='mr-2'>Sama kuin kotiosoite </span>
							<label
								className='switch m-0 mb-1'
								style={{ alignSelf: 'center' }}
							>
								<input
									type='checkbox'
									checked={customer?.delivery_different !== true}
									autoFocus={false}
									onChange={e =>
									{
										order.customer = { ...order.customer, delivery_different: !e.target.checked };
										setStore({ order });
									}}
								/>
								<span className='switchslider round' />
							</label>
						</div>
						{customer?.delivery_different === true && (
							<>
								<SearchList<AddressResult>
									renderItem={v => v?.text}
									placeholder='Katuosoite'
									htmlName='street-address'
									value={customer?.delivery_address}
									onChange={value =>
									{
										order.customer = { ...order.customer, delivery_address: value };
										setStore({ order });
									}}
									onSelect={data =>
									{
										order.customer = {
											...order.customer,
											delivery_address: data?.streetAddress,
											delivery_postal: data?.postalCode,
											delivery_city: data?.addressLocality,
										};
										setStore({ order });
									}}
								/>
								<input
									type={'text'}
									spellCheck={false}
									autoFocus={false}
									style={{ fontSize: '0.85rem' }}
									className='form-control mb-1'
									placeholder={'Postinumero'}
									required
									value={customer?.delivery_postal}
									onChange={e =>
									{
										order.customer = { ...order.customer, delivery_postal: e.target.value };
										setStore({ order });
									}}
								/>
								<input
									type={'text'}
									spellCheck={false}
									autoFocus={false}
									style={{ fontSize: '0.85rem' }}
									className='form-control mb-1'
									placeholder={'Toimipaikka'}
									required
									value={customer?.delivery_city}
									onChange={e =>
									{
										order.customer = { ...order.customer, delivery_city: e.target.value };
										setStore({ order });
									}}
								/>
							</>
						)}
						<div>
							<button
								type='button'
								className='btn btn-success m-1 float-right'
								onClick={() =>
								{
									deliveryModal?.onConfirm?.();
								}}
							>
								Tallenna
							</button>
						</div>
					</div>
				</Modal>
			)}
		</>
	);
}