import _ from 'lodash';
import moment from 'moment';
import { useStore, setStore } from 'store';
import styled, { css } from 'styled-components';
import { dateRoundUp, getDuration } from 'App';
import { colorScheme } from 'utils/ColorScheme';
import { Swipe } from './Swipe';
import { SqlDate } from 'utils/Dates';
import { Entry, Post } from 'utils/Api';
import { Slide } from 'components/Slide';
import { useMount, useStated } from 'hooks/Hooks';
import hash_sum from 'hash-sum';
import { usePrice } from 'components/UsePrice';

const Container = styled.div`
	align-items: center;
	justify-content: center;
	position: absolute;
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	gap: 2em;
	padding: 2em;
	width: 100%;

	/* padding: 16px 10px; */
`;

const WeekdayContainer = styled.div<{ selected?: boolean; reserved?: boolean; }>`
	display: flex;
	min-width: 100%;
`;

const Weekday = styled.div<{ selected?: boolean; reserved?: boolean; }>`
	flex: 1;
	padding: 0 0 8px 0;
	display: flex;
	justify-content: center;
	margin: 4px;
	cursor: pointer;

	@media (hover: hover) and (pointer: fine) {
		:hover button {
			background: #${ `${ colorScheme.mainColor }` }55;
		}
	}

	button {
		border-radius: 3px;
		transition: 150ms all ease-in-out;
		display: flex;
		justify-content: center;
		flex-wrap: nowrap;

		@media screen and (max-width: 768px) {
			flex: 1;
			flex-wrap: wrap;
			div {
				width: 100%;
			}
		}

		div:first-child {
			padding-right: 5px;
		}
	}
`;

const SlotBlock = styled.div<{ selected?: boolean; reserved?: boolean; past?: boolean; }>`
	flex: 1;
	width: 125px;
	min-width: 100px;
	min-height: 6em;
	position: relative;
	overflow: hidden;
	border: 1px solid #0008;
	border-radius: 1em;
	align-items: center;
	justify-content: center;
	text-align: center;
	flex-wrap: wrap;
	flex-direction: column;

	/* min-width: calc(100% - 10px); */
	/* min-width: 100%; */
	/* min-width: 50%; */
	/* width: 100%; */
	/* margin: 5px; */
	/* border: 1px solid #00000022; */
	/* border-radius: 5px; */
	background: #fff;
	padding: 0.5em;
	/* box-shadow: 1px 1px 2px #00000022; */
	transition: all 200ms ease-in-out;
	display: inline-flex;

	/* box-shadow: 1px 0px #ccc, 1px 0px #ccc inset, 0px 1px #eee inset; */
	box-shadow: 0px 1px #ccc inset;

	/* :first-of-type {
		border-radius: 8px 8px 0 0;
	}

	:last-of-type {
		border-radius: 0 0 8px 8px;
	} */

	${ props =>
		!props.reserved &&
		!props.past &&
		css`
			cursor: pointer;

			@media (hover: hover) and (pointer: fine) {
				:hover {
					background: #${ colorScheme.mainColor }1F;
				}
			}

			:active {
				background: #${ colorScheme.mainColor }77;
			}
		`}

	${ p =>
		p.selected &&
		css`
			color: #${ colorScheme.mainColorDark }!important;
			background: #${ colorScheme.mainColor }44!important;
		`}

	${ p =>
		p.reserved &&
		css`
			background: #00000030 !important;
		`}

	${ p =>
		p.past &&
		css`
			background: #0000000f !important;
		`}
`;

const CalendarHeader = styled.div<{ selected?: boolean; reserved?: boolean; }>`
	position: sticky;
	top: -2px;
	min-width: 100%;
	display: flex;
	flex-wrap: wrap;
	background: #fff;
	z-index: 1;
	box-shadow: #00000020 0px 10px 10px -10px;
	text-align: center;

	button {
		height: 100%;
		outline: 0;
		border: 0;
		padding: 5px 10px;
	}
`;

export function SlotsContainer()
{
	usePrice();

	const order = useStore(s => s.order);
	const { start, end, office, resource, schedules, company } = order;

	let timeStep = 60 * 4;
	if (company?.name?.match(/infoni/i)) timeStep = 60;

	const [state, set] = useStated({
		day: start ? moment(start).startOf('day') : moment().startOf('day'),
		week: start ? moment(start).startOf('day') : moment().startOf('day'),
		// prices: undefined as any,
		priceCache: {} as Record<string, any>,
		scrollTop: 0,
		swipePreview: 0,
		slide: 0,
	});

	const { week, day, swipePreview } = state;
	const _day = day.clone().add(-swipePreview, 'd');
	const nowRounded = dateRoundUp(moment(_day), timeStep).startOf('m');

	const { rangeStart, rangeEnd, blockCount } = (() =>
	{
		let rangeStart = nowRounded.clone();
		let rangeEnd = nowRounded.clone().endOf('d');

		_.map(office?.openings, v =>
		{
			if (v.weekday !== moment(rangeStart).weekday()) return;

			let opentime = moment(v?.opentime, [moment.ISO_8601, 'HH:mm']);
			let closetime = moment(v?.closetime, [moment.ISO_8601, 'HH:mm']);

			if (v.closed === true)
			{
				opentime = moment('00:00', [moment.ISO_8601, 'HH:mm']);
				closetime = moment('00:00', [moment.ISO_8601, 'HH:mm']);
			}

			rangeStart.hour(opentime.hour()).minute(opentime.minute());
			rangeEnd.hour(closetime.hour()).minute(closetime.minute());
		});

		_.map(office?.holidays, v =>
		{
			if (!moment(_day).isBetween(moment(v.opendate), moment(v.closedate), undefined, '[]')) return;

			let opentime = moment(v.opentime, [moment.ISO_8601, 'HH:mm']);
			let closetime = moment(v.closetime, [moment.ISO_8601, 'HH:mm']);

			if (v.closed === true)
			{
				opentime = moment('00:00', [moment.ISO_8601, 'HH:mm']);
				closetime = moment('00:00', [moment.ISO_8601, 'HH:mm']);
			}

			rangeStart.hour(opentime.hour()).minute(opentime.minute());
			rangeEnd.hour(closetime.hour()).minute(closetime.minute());
		});

		if (office?.return_whenever === true && start)
		{
			if (rangeStart.isAfter(start)) rangeStart.hour(0).minute(0);
			rangeEnd.hour(23).minute(59);
		}

		rangeStart = dateRoundUp(moment(_.max([Date.now(), +rangeStart])), timeStep).startOf('m');
		rangeEnd = dateRoundUp(moment(_.max([Date.now(), +rangeEnd])), timeStep).endOf('m');

		let dateRange = Math.floor(rangeEnd.diff(rangeStart, 'm', true) / timeStep);

		// console.log(moment(rangeStart).format('D.M. HH:mm'), moment(rangeEnd).format('D.M. HH:mm'));

		if (rangeStart.isSame(rangeEnd, 'hour'))
		{
			const opentime = moment('00:00', [moment.ISO_8601, 'HH:mm']);
			const closetime = moment('00:00', [moment.ISO_8601, 'HH:mm']);
			rangeStart.hour(opentime.hour()).minute(opentime.minute());
			rangeEnd.hour(closetime.hour()).minute(closetime.minute());

			console.log('suljettu');
			dateRange = 0;
		}
		else
		{
			rangeStart = rangeStart.clone().set('hour', 9);
		}

		return {
			rangeStart,
			rangeEnd,
			blockCount: dateRange,
		};
	})();

	useMount(async () =>
	{
		if (!resource) return;

		(async () =>
		{
			order.schedules = await Post({
				path: 'schedule',
				body: {
					ending: '>=' + SqlDate(moment(day).subtract(1, 'd')),
					start: '<=' + SqlDate(moment(day).add(1, 'd')),
					resource: resource?.id || resource,
				},
				header: { limit: 1000 },
			});

			setStore({ order });
		})();

		if (!start || end) return;

		const getPriceBlocks = async (resource: Entry, start: moment.Moment, ending: moment.Moment, accumulate = true) =>
		{
			if (!resource || !start || !ending) return;

			const { priceCache } = state;
			const cacheTime = Math.floor(Date.now() / 1000 / 60);
			const cacheKey = hash_sum({ cacheTime, resource, start, ending: ending, accumulate });
			if (priceCache[cacheKey]) return priceCache[cacheKey];

			const data = await Post({
				path: 'get_price_blocks',
				body: {
					start: SqlDate(start),
					ending: SqlDate(ending),
					resource: String(resource?.id || resource || ''),
					accumulate,
				},
			});

			priceCache[cacheKey] = data;
			set({ priceCache });
			return data;
		};

		// const prices = await getPriceBlocks(
		// 	resource,
		// 	moment(start).startOf('minute'),
		// 	moment(day).startOf('minute').add(1, 'day'),
		// );
		// return set({ prices });
	}, [Number(start) || 0, Number(day) || 0]);

	return (
		<Slide
			children={(slide2, content2) => (
				<>
					<CalendarHeader>
						<Slide
							children={(slide, content) => (
								<>
									<div
										style={{
											display: 'flex',
											minWidth: '100%',
											padding: '6px 4px',
										}}
									>
										{
											<button
												className='btn btn-light'
												style={{
													...(+week <= Date.now() && {
														visibility: 'hidden',
														pointerEvents: 'none',
													}),
													...(day.isBefore(week, 'week') && {
														background: `#${ colorScheme.mainColor }`,
														color: '#fff',
													}),
												}}
												onClick={() =>
												{
													slide(-100);
													set({ week: moment(week).add(-1, 'w') });
												}}
											>
												<i className='fa fa-chevron-left' />
											</button>
										}
										{content(
											<div style={{ flex: 1, textAlign: 'center', alignSelf: 'center' }}>
												{_.upperFirst(week.format('D. MMM'))} -{' '}
												{_.upperFirst(week.clone().endOf('w').format('D. MMM Y'))}
											</div>,
										)}
										<button
											className='btn btn-light'
											onClick={() =>
											{
												slide(100);
												set({ week: moment(week).add(1, 'w') });
											}}
											style={{
												...(day.isAfter(week, 'week') && {
													background: `#${ colorScheme.mainColor }`,
													color: '#fff',
												}),
											}}
										>
											<i className='fa fa-chevron-right' />
										</button>
									</div>
									{content(
										<WeekdayContainer>
											{_.map(_.range(7), v =>
											{
												const d = week.clone().startOf('w').add(v, 'd');

												return (
													<Weekday
														key={v}
														onClick={() =>
														{
															if (d.isSame(day, 'd')) return;
															if (d.isBefore(moment(), 'd')) return;
															slide2(d.isBefore(day, 'd') ? -100 : 100);
															set({ day: d.clone().startOf('day') });
														}}
													>
														<button
															className='btn btn-light'
															disabled={d.isBefore(moment(), 'd')}
															style={{
																...(((start && d.isSame(start, 'd')) ||
																	(start && end && d.isBetween(start, end, 'd', '[]'))) && {
																	background: `#${ colorScheme.mainColor }80`,
																	color: '#fff',
																}),
																...(d.isSame(day, 'd') && {
																	background: `#${ colorScheme.mainColor }`,
																	color: '#fff',
																}),
															}}
														>
															<div>{_.upperFirst(d.format('ddd'))}</div>
															<div>{_.upperFirst(d.format('D'))}</div>
														</button>
													</Weekday>
												);
											})}
										</WeekdayContainer>,
									)}
								</>
							)}
						/>
					</CalendarHeader>

					<Swipe
						onMove={x =>
						{
							const allow = !(x > 0 && +day < +moment());
							if (allow)
							{
								const ox = x > 0 ? 1 : x < 0 ? -1 : 0;
								if (ox !== swipePreview) set({ swipePreview: ox });
							}
							return allow;
						}}
						onEnd={x =>
						{
							if (x <= -1)
							{
								const c = day.clone().add(1, 'd');
								set({
									day: c.clone().startOf('day'),
									week: c.clone().startOf('w'),
									swipePreview: 0,
								});
								return true;
							}
							if (x >= 1)
							{
								const c = day.clone().add(-1, 'd');
								if (+day < Date.now()) return false;

								set({
									day: c.clone().startOf('day'),
									week: c.clone().startOf('w'),
									swipePreview: 0,
								});
								return true;
							}
							set({ swipePreview: 0 });
							return false;
						}}
					>
						{content2(
							<Container>
								{(() =>
								{
									if (!blockCount) return;


									const result: JSX.Element[] = [];
									let pricefields = resource?.pricefields as Record<string, any>[];
									if (!pricefields) return;

									pricefields = _.sortBy(pricefields, v =>
									{
										const cond = v?.conditions?.find?.((f: any) =>
										{
											if (f?.cond === 'time_range') return true;
											return false;
										})?.value;

										const start = +moment(cond?.start, [moment.ISO_8601, 'HH:mm']);
										console.log('cond:', start);
										return start;
									});

									for (let v = 0; v < (pricefields?.length ?? 0); ++v)
									{
										const cond = pricefields[v]?.conditions?.find?.((f: any) =>
										{
											console.log(f);
											if (f?.cond === 'time_range') return true;
											return false;
										})?.value;

										if (!(cond?.start || cond?.ending)) continue;

										console.log(resource?.reservation_mode, cond);

										const startTime = moment(cond?.start, [moment.ISO_8601, 'HH:mm']);
										const endTime = moment(cond?.ending, [moment.ISO_8601, 'HH:mm']);

										console.log(startTime, endTime.toString());

										const d = moment(rangeStart).set('hour', startTime.hour());

										if (+d < +rangeStart) continue;
										if (+d >= +rangeEnd) continue;

										// const d = moment(rangeStart).add(v * timeStep, 'minutes');

										// const price = _.find(prices, (f: any) => moment(f?.ending).isSameOrAfter(d));
										// const nextPrice = _.find(prices, (f: any) =>
										// 	moment(f?.ending).isSameOrAfter(moment(d).add(timeStep, 'minutes')),
										// );

										// const selected = Boolean(
										// 	(start && end && +d >= +start && +d <= +end) || (start && +d === +start),
										// );

										const selected = Boolean(
											(start && end && +d >= +start && +d < +end) || (start && +d === +start),
										);

										const reserved = _.find(
											schedules?.data,
											(f: any) =>
												f?.resource?.id === resource?.id &&
												(moment(d).isBetween(moment(f?.start), moment(f?.ending), undefined, '[)') ||
													moment(d).isBetween(moment(f?.start), moment(f?.ending), undefined, '[)')),
										);

										const overlap = _.find(
											schedules?.data,
											(f: any) =>
												f?.resource?.id === resource?.id &&
												start &&
												// d.isBetween(moment(f?.start), moment(f?.ending),undefined,'[]')
												+d >= +moment(f?.start) &&
												+start < +moment(f?.ending),
										);

										// const overlap2 = _.find(schedules?.data, (a: any) =>
										// {
										// 	if (a?.resource?.id !== resource?.id)return;
										// 	return _.find(
										// 		schedules?.data,
										// 		(b: any) =>
										// 			b?.id !== a?.id &&
										// 			b?.resource?.id === a?.resource?.id &&
										// 			(moment(a?.start).isBetween(moment(b?.start), moment(b?.ending),'minute','[]') ||
										// 				moment(a?.ending).isBetween(moment(b?.start), moment(b?.ending)),'minute','[]'),
										// 		// moment(a?.start).isBetween(moment(b?.start), moment(b?.ending))
										// 		// +d >= +moment(a?.start) &&
										// 		// +start < +moment(a?.ending),
										// 	);
										// });

										if (d >= moment(+rangeStart).startOf('d').add(1, 'd')) continue;

										result.push(
											<SlotBlock
												key={v}
												selected={selected}
												past={+d < Date.now()}
												reserved={Boolean(reserved  )}
												onClick={async () =>
												{
													// if (overlap) return;
													if (schedules === undefined || reserved || +d <= Date.now()) return;

													if ((!end && !start) || +d !== +start)
													{
														order.start = d.toDate();
														order.end = moment(rangeStart).set('hour', endTime.hour()).toDate();
														return setStore({ order });
													}

													order.start = undefined;
													order.end = undefined;
													order.calcprice = undefined;
													return setStore({ order });
												}}
											>
												<div>
													<div style={{ fontWeight: 400, textShadow: '0px 0px 1px #555' }}>
														{moment(d).format('HH:mm')}

														<>
															{'\u2000'}-{'\u2000'}
														</>

														{moment(rangeStart).set('hour', endTime.hour()).format('HH:mm')}
													</div>

													{Boolean(start && +start === +d) && (
														<div className='ml-1'>
															<i className='fa fa-caret-right ml-1 mr-1' /> Valittu
															<div>{order?.calcprice?.price.toLocaleString('fi')} €</div>
														</div>
													)}

													{/* {Boolean(end && +end === +d) && (
														<span className='ml-1'>
															<i className='fa fa-caret-left ml-1 mr-1' /> Päättyy
														</span>
													)} */}

													{/* <div>
														{start && +start === +d && !end ? (
															<small>Valitse seuraavaksi päättymisaika</small>
														) : (
															<br />
														)}
													</div> */}
												</div>
												<div
													style={{
														fontWeight: 400,
														color: `#${ colorScheme.mainColorDark }`,
														// marginLeft: 'auto',
													}}
												>
													{/* {
																start && d >= start && price?.price &&
																(!prevPrice?.price || prevPrice?.price !== price?.price || (d === end)) && 
																<>
																	<>{ start && getDuration(start, +d) } </>
																	<> <i className='fa fa-caret-left ml-1 mr-1' />  </>
																	<> { price?.price } € </>
																	{(()=>{
																		prevPrice = price;
																	})()}
																</>
														} */}
													{(() =>
													{
														if (reserved) return <div style={{ fontWeight: 400, color: '#666' }}>Varattu</div>;
														if (overlap)
														{
															return (
																<div style={{ fontWeight: 400, color: '#666' }}>
																	<br />
																</div>
															);
														}
														if (+d <= Date.now())
														{
															return (
																<div style={{ fontWeight: 400, color: '#666' }}>
																	<br />
																</div>
															);
														}
														// if (start && end && +end === +d)
														// {
														// 	return (
														// 		<>
														// 			{getDuration(start, end)} <i className='fa fa-caret-right ml-1 mr-1' />{' '}
														// 			{price?.price} €
														// 		</>
														// 	);
														// }
														// if (start && !overlap && nextPrice && nextPrice?.price !== price?.price)
														// {
														// 	return (
														// 		<>
														// 			{getDuration(start, d)} <i className='fa fa-caret-left ml-1 mr-1' />{' '}
														// 			{price?.price} €
														// 		</>
														// 	);
														// }
														return null;
													})()}
												</div>
												{/* {
														(reserved && <div style={{ fontWeight: 400, color: '#666' }}>Varattu</div>) ||
														(overlap && <div style={{ fontWeight: 400, color: '#666' }}><br /></div>) ||
														(+d <= +moment() && <div style={{ fontWeight: 400, color: '#666' }}><br /></div>) ||
														<div style={{ fontWeight: 400, color: `#${colorScheme.mainColorDark}` }}>{price?.price} €</div>
													} */}
											</SlotBlock>,
										);
									}

									return result;
								})()}

								{blockCount === 0 && (
									<div style={{ flex: 1, textAlign: 'center', marginTop: '10px', padding: '20px' }}>
										Ei aikoja {moment(_day).format('D.M.Y')}
										<br />
										<button
											className='btn btn-success mt-3'
											onClick={() =>
											{
												slide2(100);
												set({ day: day.clone().add(1, 'd') });
											}}
										>
											Seuraava päivä <i className='fa fa-caret-right ml-1' />
										</button>
									</div>
								)}
							</Container>,
						)}
					</Swipe>
				</>
			)}
		/>
	);
}